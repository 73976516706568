import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import * as showdown from 'showdown';
import SEO from '../components/seo';
const converter = new showdown.Converter();

export const BasicPageTemplate = ({
  title,
  content,
}: {
  title: string;
  content: any;
}) => {
  return (
    <>
      <SEO title={title} />
      <div className="row">
        <div className="contain-row">
          <div className="col-xs-12">
            <h1 className="mt-u4 mb-u2 font-u3">{title}</h1>
            <div
              className="markdown-holder mb-u6 font-u1"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const BasicPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title = '', content = '' },
    },
  },
}) => {
  return (
    <Layout>
      <BasicPageTemplate title={title} content={content} />
    </Layout>
  );
};

export default BasicPage;

export const basicPageQuery = graphql`
  query BasicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
      }
    }
  }
`;
